import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

import Surprised from "../svg/surprised.inline.svg"

const NotFoundPage = () => (
  <Layout>
    <article className="NotFoundPage">
      <div className="NotFoundPage__TextContainer">
        <h1 className="NotFoundPage__Header">Error 404</h1>
        <section className="content-body">
          Uh oh! The page you're looking for can't be found. Try heading to the <Link className="NotFoundPage__Link" to="/">homepage</Link> and see if you can find what you're looking for.
        </section>
      </div>
      <Surprised className="NotFoundPage__Surprised"/>
    </article>
  </Layout>
)

export default NotFoundPage
